const feedbackUrl = "https://forms.gle/vNcUYTzpnqcMTDkv7";

const Feedback = () => (
  <div className="feedback-container">
    {/* eslint-disable-next-line */}
    <a href={feedbackUrl} className="feedback-widget" target="_blank">
      <span>Give feedback</span>
      <svg
        fill="none"
        stroke="currentcolor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        viewBox="0 0 16 16"
        role="img"
        aria-labelledby="title-4744738674102027"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title id="title-4744738674102027">External link icon</title>
        <path d="M6.75 1.75h-5v12.5h12.5v-5m0-4v-3.5h-3.5M8 8l5.5-5.5"></path>
      </svg>
    </a>
  </div>
);

export default Feedback;
